import React, { useState } from 'react' 
import { Helmet } from 'react-helmet'
import Header from '../widgets/Header'
import Sidebar from '../widgets/Sidebar'
import { CONFIG } from '../constants' 
import AgentAdd from './AgentAdd'
import "./style.css"

const AgentList = () => {
    const [showAdd, setShowAdd] = useState(false)

    const handleClosePopup = () => {
        // Handle closing logic here
        setShowAdd(false);
      };

    return (
        <>
            <Helmet>
                <title> AgentList | {CONFIG.APP_NAME} </title>
            </Helmet>

            <div className="wrapper">
                <Header />
                <Sidebar />


                <div className="content-page">
                    <div className="row col-md-12">
                        <div className="col-md-12 row">
                            <div className="col-md-9"><h4>Agent List </h4></div>
                            <div className="col-md-3"><button className='btn btn-primary float-right' onClick={() => { setShowAdd(true) }}  > Add New Agent </button></div>

                        </div>
                        <div className="col-md-12"> <hr /></div>
                         <div className="col-md-12 row">
                            <table className="table">
                                <tr>
                                    <th>SL</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Reg. Date</th>
                                    <th>Last Login</th>
                                    <th>Action</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Admin</td>
                                    <td>admin@admin.co</td>
                                    <td>9988989767</td>
                                    <td>11-01-2023</td>
                                    <td>11-02-2024</td>
                                    <td>
                                        <button className='btn btn-primary' >Edit</button> &nbsp; 
                                        <button className='btn btn-danger' >Delete</button>
                                    </td>
                                </tr>
                            </table>
                         </div>
                    </div>
                </div>
            </div>

            {showAdd && <AgentAdd onClose={handleClosePopup} />}
        </>
    )
}

export default AgentList