import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../widgets/Header'
import Sidebar from '../widgets/Sidebar'
import { CONFIG } from '../constants'
import MSGBox from '../widgets/MSGBox'
import { Link } from 'react-router-dom'

const Notifications = () => {
  return (
    <>
      <Helmet>
        <title> Notifications | {CONFIG.APP_NAME} </title>
      </Helmet>

      <div className="wrapper">
        <Header />
        <Sidebar />


        <div className="content-page">
          <div className="row col-md-12">
            <div className="col-md-12">
              <h4>Notifications</h4>
              <hr />
            </div>
            <div className="col-md-4 msg-list">
              <Link to={`/notifications/${1}`}>
                <MSGBox name={"Admin"} date={"10-12-2023"} />
              </Link>
              <MSGBox name={"Server Admin"} date={"11-12-2023"} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notifications