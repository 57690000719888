import {React, useEffect, useState} from 'react'
import { Helmet } from 'react-helmet'
import IntroHeader from './IntroHeader'
import IntroFooter from './IntroFooter'

const Frontend = ({ title, Content }) => {

    const [loaderStatus, setLoaderStatus] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoaderStatus(false)
        }, 1000);
    }, [])
    return (
        <>
            <Helmet>
                <title>{ title } | DSI LLC</title>
                <link rel="stylesheet" href="/assets/css/all.min.css" />
                <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
                <link rel="stylesheet" href="/assets/css/swiper-bundle.min.css" />
                <link rel="stylesheet" href="/assets/css/meanmenu.css" />
                <link rel="stylesheet" href="/assets/css/magnific-popup.css" />
                <link rel="stylesheet" href="/assets/css/nice-select.css" />
                <link rel="stylesheet" href="/assets/css/backtotop.css" />
                <link rel="stylesheet" href="/assets/css/main.css" />
            </Helmet>
            {loaderStatus && <div className="loader" id='loader'></div>}
            <div class="has-smooth" id="has_smooth"></div>

            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <div class="body-wrapper">
                        <IntroHeader />

                            <Content />

                        <IntroFooter />


                    </div>
                </div>
            </div>
        </>
    )
}

export default Frontend