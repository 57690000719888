import React from 'react'

const MSGBox = ({ name, phone, date, time, chat_id, seen, active }) => {

    const statusColor = { 
        position: "absolute",
        background: "green",
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        marginTop: "-40px",
        left: "20px" 
    }

    return (
        <>
            <div className='msg-box' style={{ background: active==="Yes" ? "#cdf7ed": "#f0fffa" }}  >
                { seen =="No" && <div className="status-dot" style={statusColor} ></div> }
                <div className="thumb">{name[0]}</div>
                <div className="desc">

                    <p style={{ margin: "0" }} ><b> {name && name}</b><br /> {phone && phone}</p>
                    <span> <i>{date && date}  {time && time}</i> </span>
                </div>
            </div>
        </>
    )


}

export default MSGBox