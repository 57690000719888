import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = () => {
  return (
    <>
      <div className="small-saidbar">
        <div className="iq-sidebar-logo d-flex justify-content-between">
          <Link to="/dashboard">
            <img src="/assets/images/logo.svg" className="img-fluid" alt="logo" />
          </Link>
          <div className="iq-menu-bt-sidebar">
            <div className="iq-menu-bt align-self-center">
              <div className="wrapper-menu open">
                <div className="main-circle"><i className="las la-times"></i></div>
              </div>
            </div>
          </div>
        </div>
        <nav className="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" className="iq-menu">
            <li className="active">
              <Link to="/dashboard" className="">
                <i className="las la-home iq-arrow-left"></i><span className="menu-text">Dashboard</span>
              </Link>
            </li>
            <li className="">
              <Link to="/chat" className="collapsed">
                <i className="las la-check-square iq-arrow-left"></i><span className="menu-text">Chat</span>
              </Link>
            </li>
            <li className="">
              <Link to="/notifications" className="">
                <i className="las la-bell iq-arrow-left"></i><span className="menu-text">Notification</span>
              </Link>
            </li>
            <li className="">
              <Link to="#user" className="collapsed svg-icon" data-toggle="collapse" aria-expanded="false">
                <i className="las la-user-check iq-arrow-left"></i><span>User Details</span>
              </Link>
              <ul id="user" className="iq-submenu collapse" data-parent="#user">

                <li className="">
                  <Link to="/dashboard/agent-list">
                    <i className="las la-user-plus"></i><span className="">Add Agent</span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/dashboard/agent-list">
                    <i className="las la-user-edit"></i><span className="">Agent List</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className=" ">
              <Link to="#uielements" className="collapsed" data-toggle="collapse" aria-expanded="false">
                <i className="las la-border-all iq-arrow-left"></i><span> Settings </span>
              </Link>
              <ul id="uielements" className="iq-submenu collapse" data-parent="#uielements">
                <li className="">
                  <Link to="/dashboard/subscriptions">
                    <i className="las la-user-circle"></i><span>Subscriptions</span>
                  </Link>
                </li>

                <li className=" ">
                  <Link to="/dashboard/api">
                    <i className="lab la-atlassian"></i><span>API Access</span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/dashboard/transactions">
                    <i className="las la-bars"></i><span>Transaction Details</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="">
              <Link to="#form" className="collapsed" data-toggle="collapse" aria-expanded="false">
                <i className="las la-clipboard iq-arrow-left"></i><span>Reports</span>
              </Link>
              <ul id="form" className="iq-submenu collapse" data-parent="#form">
                <li className="">
                  <Link to="/dashboard/analytics">
                    <i className="las la-book"></i><span className="">Analytic Report</span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/dashboard/engagement" className="svg-icon">
                    <i className="las la-keyboard"></i><span className="">User Engagement</span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/help" className="svg-icon">
                    <i className="las la-edit"></i><span className="">Help and guides</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className=" ">
              <Link to="/docs" className="collapsed">
                <i className="lab la-readme iq-arrow-left"></i><span>Developer Docs</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Sidebar