import React, { useEffect, useState } from 'react'
import Sidebar from '../widgets/Sidebar'
import Header from '../widgets/Header'
import "../style.css";
import MSGBox from '../widgets/MSGBox';
import axios from 'axios';
import { CONFIG, getPara } from '../constants';
import { Helmet } from 'react-helmet';
import { Link, redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

const ChatHistory = () => {
    const [members, setMembers] = useState([])
    const [isChat, setIsChat] = useState(false)
    const [chatId, setChatId] = useState()
    const [isLoaded, setIsloaded] = useState(false)
    const [active, setActive] = useState()
    const [chatURL, setChatURL] = useState('')

    useEffect(() => {
        if (getPara("chat", true) != undefined) {
            setIsChat(true)
            setChatId(getPara("chat", true))
        }
        if (!isLoaded) {
            setIsloaded(true)
            getMembers()
        }

        const interval = setInterval(getMembers, 3000); // Call every 3 seconds

        return () => {
            clearInterval(interval); // Cleanup function to clear interval when component unmounts
        };

    }, [])

    const getMembers = async () => {
        const formData = new FormData();
        formData.append("uid", CONFIG.uuid)
        formData.append("lid", document.getElementById("lid").innerText)
        formData.append("api_key", CONFIG.API_KEY)
        formData.append("GET_CHAT_MEMBERS_CLOSED", "true")

        var resp = await axios.post(CONFIG.APP_API, formData);
        console.warn(resp.data);
        if (resp.data.status != 401) {
            const newLastEntry = resp.data.result[0].id;
            document.getElementById("lid").innerText = newLastEntry;
            setMembers(members => [...resp.data.result])
        }
    }

    const closeChat = async () => {
        const cookie = new Cookies();
        const formData = new FormData();
        formData.append("chat_id", getPara("chat", false));
        formData.append("api_key", cookie.get("api_key"));
        formData.append("END_CHAT", cookie.get("api_key"));
        formData.append("uid", cookie.get("uuid"));
        const resp = await axios.post(CONFIG.APP_API, formData); 
    }
    return (
        <>
            <div id="lid">0</div>
            <Helmet>
                <title>Manage Chat | DSI Chat </title>
            </Helmet>
            <div class="wrapper">
                <Header />
                <Sidebar />

                <div class="content-page">

                    <div className="row col-md-12">
                        <div className="col-md-12 row">
                            <div className="col-md-10">
                                <h4> Previous chats</h4>
                            </div>
                            <div className="col-md-2">
                                <Link to={"/chat"} ><button className='btn btn-secondary'>Active Chat</button></Link>
                            </div>
                        </div>
                        <div className="col-md-12"><hr /></div>
                        <div className="col-md-4 msg-list">
                            {members.length > 0 && members.map((itm, i) =>
                                <div onClick={() => {
                                    setActive(i);
                                    setIsChat(true);
                                    setChatURL(`https://talk.devsecit.com/agentchat/${itm.chat_id}/uuid/${CONFIG.uuid}/name/${CONFIG.name}`);
                                }}>
                                    <Link to={'/chat-history/' + itm.chat_id} > <MSGBox
                                        key={i}
                                        date={itm.date}
                                        phone={itm.phone}
                                        name={itm.name}
                                        time={itm.time}
                                        chat_id={itm.chat_id}
                                        seen={itm.seen}
                                        active={active === i ? "Yes" : "No"}
                                    />

                                    </Link>
                                </div>)}

                        </div>

                        <div className="col-md-8">
                            {isChat ? <>
                                <iframe src={chatURL} frameborder="0" className='chat_iframe'></iframe>
                                <button id="chatClosingButton" className='btn btn-warning' onClick={() => {
                                    setIsChat(false);
                                }}  > Close Chat</button>
                            </> :
                                <div class="chat-content animate__animated animate__fadeIn active"
                                    data-toggle-extra="tab-content" id="default">
                                    <div class="vh-100 px-3 px-md-4 d-flex align-items-center justify-content-center">
                                        <div class="chat-tab-box default-box d-flex align-items-center">
                                            <div class="text-center mx-auto d-block pb-5 pt-0">
                                                <img src="/assets/images/layouts/layout-06.png" class="img-fluid w-60"
                                                    alt="new-chat" style={{ maxHeight: "50vh", width: "auto" }} />
                                                <h3 class="mb-1 pt-4">Start Conversation with your contacts</h3>
                                                <p class="mb-0 mb-4">
                                                </p>
                                                <button type="button" class="btn btn-primary default-chat-btn">
                                                    Contact List
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>



                </div>
            </div>
        </>
    )
}

export default ChatHistory