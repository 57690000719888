import axios from 'axios'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { CONFIG } from '../constants'
import Cookies from 'universal-cookie'
import { redirect } from 'react-router-dom'

const AuthScreen = () => {
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const agentLogin = async () => {
        const formData = new FormData();
        formData.append("email", email)
        formData.append("password", password)
        formData.append("AGENT_LOGIN", "true")

        const resp = await axios.post(CONFIG.APP_API, formData);
        console.warn(resp.data);
        if (resp.data.status === "200") {
            const cookie = new Cookies();
            cookie.set("auth_dsi", resp.data.uuid);
            cookie.set("uuid", resp.data.uuid);
            cookie.set("api_key", resp.data.api_key);
            cookie.set("email", resp.data.result.email);
            cookie.set("rank", resp.data.result.rank);
            cookie.set("name", resp.data.result.name);
            // redirect("/dashboard");
            window.location.href='/dashboard'
        } else {
            setErrorMessage(resp.data.message);
            setErrorShow(true);
            setTimeout(() => {
                setErrorShow(false);
            }, 5000);
        }

    }

    return (
        <>
            <Helmet>
                <title>Signup - DSI Talk</title>
            </Helmet>

            <div className="wrapper">
                <section className="login-content">
                    <div className="container">
                        <div className="row align-items-center justify-content-center height-self-center">
                            <div className="col-lg-8">
                                <div className="card auth-card bg-light">
                                    <div className="card-body p-0">
                                        <div className="row align-items-center auth-content">
                                            <div className="col-lg-7 align-self-center">
                                                <div className="p-3">
                                                    <img src="/assets/images/login/mail.png" className="rounded avatar-80 mb-3" alt="" />
                                                    <h2 className="mb-2">Hi ! Welcome Back</h2>
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="floating-label form-group">
                                                                    <input className="floating-input form-control" type="text" onChange={(e) => { setEmail(e.target.value) }} placeholder='' />
                                                                    <label>Username</label>
                                                                </div>
                                                                <div className="floating-label form-group">
                                                                    <input className="floating-input form-control" type="password" onChange={(e) => { setPassword(e.target.value) }} placeholder='' />
                                                                    <label>Password</label>
                                                                </div>
                                                            </div>
                                                            { errorShow && <p className='text-danger' style={{marginLeft:"30px"}}>{errorMessage}</p> }
                                                        </div>
                                                       
                                                        <button type="button" onClick={() => { agentLogin() }} className="btn btn-primary">CONTINUE</button>
                                                        <center>
                                                            <br />
                                                            <i><big>The authentication page will change shortly</big></i>
                                                        </center>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 content-right">
                                                <img src="/assets/images/login/01.png" className="img-fluid image-right" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )

}


export default AuthScreen