import React from 'react'

const IntroFooter = () => {
    return (
        <>
            {/* <!-- footer area start --> */}
            <footer class="h2_footer-area">
                 
                <div class="h2_footer-bottom">
                    <div class="container">
                        <div class="row justify-content-sm-between align-items-center justify-content-center">
                            <div class="col-xl-4 col-lg-5 col-md-12 order-3 order-lg-0 tp_fade_bottom_footer">
                                <div class="h2_footer-bottom-copyright text-center text-lg-start">
                                    <p>&copy; 2017, all rights reserved</p>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-sm-6 col-auto tp_fade_bottom_footer">
                                <div class="h2_footer-bottom-logo mb-10 mb-lg-0">
                                    <a href="/"><img src="/assets/images/logo/logo-w.png"
                                        alt="Image Not Found" /></a>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-sm-6 col-auto tp_fade_bottom_footer">
                                <div class="h2_footer-bottom-social mb-10 mb-lg-0">
                                    <ul>
                                        <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-dribbble"></i></a></li>
                                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default IntroFooter