import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../widgets/Header'
import Sidebar from '../widgets/Sidebar'
import { CONFIG } from '../constants'

const Subscriptions = () => {
  return (
    <>
    <Helmet>
        <title> Coming Soon | {CONFIG.APP_NAME} </title>
    </Helmet>

    <div className="wrapper">
        <Header />
        <Sidebar />


        <div className="content-page">
            <h1>Coming soon...</h1>
             
        </div>
    </div>
</>
  )
}

export default Subscriptions