import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

const Header = () => {
    const [ email, setEmail ] = useState()

    useEffect(()=>{
        const cookie = new Cookies(); 
        setEmail(cookie.get("email"))
    },[])

    return (
        <>
            <div className="iq-top-navbar">
                <div className="iq-navbar-custom">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="iq-navbar-logo d-flex align-items-center justify-content-between">
                            {/* <i className="ri-menu-line wrapper-menu"></i> */}
                            <i class="las la-bars wrapper-menu"></i>

                            <a href="/dashboard" className="header-logo">
                                <img src="/assets/images/logo-full.svg" className="img-fluid" alt="logo" />
                            </a>
                        </div>
                        <div className="iq-search-bar-header device-search">
                            <form action="#" className="searchbox">
                                <a className="search-link" href="#"><i className="las la-search bg-light font-size-20"></i></a>
                                <input type="text" className="text search-input bg-light" placeholder="Search here..." />
                            </form>
                        </div>
                        <div className="d-flex align-items-center">
                            <ul className="navbar-nav ml-auto navbar-list align-items-center">
                                <li className="nav-item nav-icon search-content">
                                    <a href="#" className="search-toggle rounded" id="dropdownSearch" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <i className="las la-search bg-light font-size-20 iq-card-icon-small"></i>
                                    </a>
                                    <div className="iq-search-bar iq-sub-dropdown dropdown-menu"
                                        aria-labelledby="dropdownSearch">
                                        <form action="#" className="searchbox device">
                                            <div className="form-group mb-0 position-relative">
                                                <input type="text" className="text search-input font-size-12 bg-light"
                                                    placeholder="type here to search..." />
                                                <a href="#" className="search-link"><i className="las la-search"></i></a>
                                            </div>
                                        </form>
                                    </div>
                                </li>

                                <li className="nav-item nav-icon dropdown">
                                    <a href="#" className="search-toggle dropdown-toggle" id="dropdownMenuButton"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="las la-bell bg-light font-size-20 iq-card-icon-small"></i>
                                    </a>
                                    <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <div className="card shadow-none m-0">
                                            <div className="card-body p-0 ">
                                                <div className="cust-title p-3">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h5 className="mb-0">Notifications</h5>
                                                        <a className="badge badge-primary badge-card" href="#">3</a>
                                                    </div>
                                                </div>
                                                <div className="px-3 pt-0 pb-0 sub-card">
                                                    <a href="#" className="iq-sub-card">
                                                        <div className="media align-items-center cust-card py-3 border-bottom">
                                                            <div className="">
                                                                <img className="avatar-50 rounded-small"
                                                                    src="../assets/images/user/01.jpg" alt="01" />
                                                            </div>
                                                            <div className="media-body ml-3">
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between">
                                                                    <h6 className="mb-0">Emma Watson</h6>
                                                                    <small className="text-dark"><b>12 : 47 pm</b></small>
                                                                </div>
                                                                <small className="mb-0">Lorem ipsum dolor sit amet</small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="iq-sub-card">
                                                        <div className="media align-items-center cust-card py-3 border-bottom">
                                                            <div className="">
                                                                <img className="avatar-50 rounded-small"
                                                                    src="../assets/images/user/02.jpg" alt="02" />
                                                            </div>
                                                            <div className="media-body ml-3">
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between">
                                                                    <h6 className="mb-0">Ashlynn Franci</h6>
                                                                    <small className="text-dark"><b>11 : 30 pm</b></small>
                                                                </div>
                                                                <small className="mb-0">Lorem ipsum dolor sit amet</small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="#" className="iq-sub-card">
                                                        <div className="media align-items-center cust-card py-3">
                                                            <div className="">
                                                                <img className="avatar-50 rounded-small"
                                                                    src="../assets/images/user/03.jpg" alt="03" />
                                                            </div>
                                                            <div className="media-body ml-3">
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between">
                                                                    <h6 className="mb-0">Kianna Carder</h6>
                                                                    <small className="text-dark"><b>11 : 21 pm</b></small>
                                                                </div>
                                                                <small className="mb-0">Lorem ipsum dolor sit amet</small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <a className="right-ic btn btn-primary btn-block position-relative p-2"
                                                    href="notification.html" role="button">
                                                    View All
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item nav-icon dropdown caption-content">
                                    <a href="#" className="search-toggle dropdown-toggle" id="dropdownMenuButton4"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="/assets/images/login/mail.png" className="img-fluid rounded" alt="user" />
                                    </a>
                                    <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <div className="card shadow-none m-0">
                                            <div className="card-body p-0 text-center">
                                                <div className="media-body profile-detail text-center">
                                                    <img src="../assets/images/page-img/profile-bg.png" alt="profile-bg"
                                                        className="profile-bg img-fluid mb-4" />
                                                    <img src="/assets/images/login/mail.png" alt="profile-img"
                                                        className="rounded profile-img img-fluid avatar-70" />
                                                </div>
                                                <div className="p-3">
                                                    <h5 className="mb-1"> { email && email } </h5>
                                                    <div className="d-flex align-items-center justify-content-center mt-3">

                                                        <a href="/change-profile" className="btn border w-100">Change Profile</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>

        </>
    )
}

export default Header