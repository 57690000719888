import React from 'react'
import { Link } from 'react-router-dom'

const AgentAdd = ({ onClose }) => {
  return (
    <div className='addAgent' >
      <div className="col-md-12 row">
        <div className="col-md-10">
          <h4 style={{ color: "white" }} >Add New Agent </h4>
        </div>
        <div className="col-md-2">
          <button className="btn btn-secondary" onClick={onClose} >Close</button>

        </div>
      </div>
      <div className="col-md-12"><hr /></div>
      <div className="col-md-12 row">
        <p>Enter name</p>
        <input type="text" name="name" id="name" placeholder='Enter name' className='form-control' />

        <p>Enter mobile no </p>
        <input type="text" name="name" id="name" placeholder='Enter mobile no' className='form-control' />
        <p>Enter email id </p>
        <input type="text" name="name" id="name" placeholder='Enter email id' className='form-control' />

        <p>Enter password </p>
        <input type="password" name="name" id="name" placeholder='Enter password' className='form-control' />
        <br /><br />
        <button className='btn btn-secondary w-100' >CREATE NEW AGENT</button>
 
      </div>
      <center> 
        <br />
          <Link to={"/help"} target='_blank' style={{ color:"white" }}> Help & Supports </Link>
        </center>
    </div>
  )
}

export default AgentAdd