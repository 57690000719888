import { Route, Routes, useLocation, BrowserRouter as Router } from "react-router-dom";
import { IntroScreen } from "./frontend/IntroScreen";
import AuthScreen from "./backend/AuthScreen";
import Dashboard from "./backend/Dashboard";
import ChatScreen from "./backend/ChatScreen";
import Contact from "./frontend/Contact";
import Documentation from "./docs/Documentation";
import { useEffect, useState } from "react";
import Loader from "./widgets/Loader";
import P404 from "./frontend/P404";
import { Auth, ProtectedRoute } from "./model/ProtectedRoute";
import Cookies from "universal-cookie";
import Notifications from "./backend/Notifications";
import AgentList from "./backend/AgentList";
import Subscriptions from "./backend/Subscriptions";
import APIDetails from "./backend/APIDetails";
import TransactionDetails from "./backend/TransactionDetails";
import ReportAnalytics from "./backend/ReportAnalytics";
import ReportEngagement from "./backend/ReportEngagement";
import ChatHistory from "./backend/ChatHistory";

function App() {
  const [isAuth, setIsAuth] = useState(false)
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const cookie = new Cookies();
    const uid = cookie.get("auth_dsi");
    if (uid === undefined || uid === null) {
      setIsAuth(false)
    } else {
      setIsAuth(true)
    }
  }, [])
  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleEnd = () => setLoading(false);

    const startListener = () => handleStart();
    const endListener = () => handleEnd();

    // Listen for route changes
    const startEvent = 'routeChangeStart';
    const endEvent = 'routeChangeEnd';

    window.addEventListener(startEvent, startListener);
    window.addEventListener(endEvent, endListener);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener(startEvent, startListener);
      window.removeEventListener(endEvent, endListener);
    };
  }, []);

  return (

    <>
      {loading && <Loader />}
      {/* <Loader /> */}

      <Routes>


        <Route exact path="/" Component={IntroScreen} ></Route>
        <Route exact path="/signup" Component={AuthScreen} ></Route>


        <Route exact path="/contact" Component={Contact} ></Route>
        <Route exact path="/docs" Component={Documentation} ></Route>
        <Route exact path="/documentation" Component={Documentation} ></Route>


        <Route exact path="/dashboard" element={isAuth ? <Dashboard /> : <AuthScreen />} > </Route>
        <Route exact path="/chat" element={isAuth ? <ChatScreen /> : <AuthScreen />} > </Route>
        <Route exact path="/chat/:id" element={isAuth ? <ChatScreen /> : <AuthScreen />} > </Route>
        <Route exact path="/chat-history" element={isAuth ? <ChatHistory /> : <AuthScreen />} > </Route>
        <Route exact path="/chat-history/:id" element={isAuth ? <ChatHistory /> : <AuthScreen />} > </Route>
        <Route exact path="/notifications" element={isAuth ? <Notifications /> : <AuthScreen />} > </Route>
        <Route exact path="/notifications/:id" element={isAuth ? <Notifications /> : <AuthScreen />} > </Route>
        <Route exact path="/dashboard/agent-list" element={isAuth ? <AgentList /> : <AuthScreen />} > </Route>
        <Route exact path="/dashboard/subscriptions" element={isAuth ? <Subscriptions /> : <AuthScreen />} > </Route>
        <Route exact path="/dashboard/api" element={isAuth ? <APIDetails /> : <AuthScreen />} > </Route>
        <Route exact path="/dashboard/transactions" element={isAuth ? <TransactionDetails /> : <AuthScreen />} > </Route>
        <Route exact path="/dashboard/analytics" element={isAuth ? <ReportAnalytics /> : <AuthScreen />} > </Route>
        <Route exact path="/dashboard/engagement" element={isAuth ? <ReportEngagement /> : <AuthScreen />} > </Route>

        {/* <ProtectedRoute path="/dashboard" component={Dashboard}  /> */}


        <Route exact path={"*"} Component={P404} />

      </Routes>

    </>
  );
}

export default App;
