const CONFIG = {
    APP_NAME: "DSI Chat",
    APP_API: "https://talk.devsecit.com/api/v1/", 
    API_KEY: "1122334455",
    name: "DSI Support", 
    uuid: "1122",
}


const getPara = (parameter, isLast) => {
    return isLast ? window.location.href.split(`/${parameter}/`)[1] : window.location.href.split(`/${parameter}/`)[1].split('/')[0];
}

const LOGOUT = () => {
    // const cookies = new Cookies();
    // cookies.set('token', '0', { path: '/' });
    // cookies.remove('token', { path: '/' });
    window.location.href = '/';
}

export { CONFIG, getPara, LOGOUT }