import {React, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import IntroHeader from '../frontend/IntroHeader'
import IntroFooter from '../frontend/IntroFooter'

const Documentation = () => {

    const [loaderStatus, setLoaderStatus] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoaderStatus(false)
        }, 1000);
    }, [])
  return (
    <>
        <Helmet>
                <title>DSI Chat - Documentation | DSI LLC</title>
                <link rel="stylesheet" href="assets/css/all.min.css" />
                <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
                <link rel="stylesheet" href="assets/css/swiper-bundle.min.css" />
                <link rel="stylesheet" href="assets/css/meanmenu.css" />
                <link rel="stylesheet" href="assets/css/magnific-popup.css" />
                <link rel="stylesheet" href="assets/css/nice-select.css" />
                <link rel="stylesheet" href="assets/css/backtotop.css" />
                <link rel="stylesheet" href="assets/css/main.css" />
            </Helmet>
            {loaderStatus && <div className="loader" id='loader'></div>}
            <div class="has-smooth" id="has_smooth"></div>

            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <div class="body-wrapper">
                        <IntroHeader />
                        <div class="h2_footer-top pt-130 pb-135 bg-default"
                            data-background="assets/images/footer/home2/bg.jpg">
                            <div class="container">
                                <div class="row justify-content-between">
                                    <div class="col-12">
                                        <div class="h2_footer-top-content">
                                            <h2 class="h2_footer-top-title tp_fade_bottom">Coming soon...</h2>
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="h2_footer-shape">
                                <img src="assets/images/footer/home2/shape-1.png" alt="" class="h2_footer-shape-1"
                                    data-speed="0.8" />
                                <img src="assets/images/footer/home2/shape-2.png" alt="" class="h2_footer-shape-2"
                                    data-speed="0.8" />
                                <img src="assets/images/footer/home2/shape-3.png" alt="" class="h2_footer-shape-3"
                                    data-speed="0.8" />
                                <img src="assets/images/footer/home2/shape-4.png" alt="" class="h2_footer-shape-4"
                                    data-speed="0.8" />
                            </div>
                        </div>
                        <IntroFooter />

                         
                    </div>
                </div>
            </div>
    </>
  )
}

export default Documentation