import React from 'react'
import Frontend from './Frontend'
import { Link } from 'react-router-dom'

const P404 = () => {
    return (
        <>
            <Frontend title={"404! Page not found"} Content={Page404} />
        </>
    )
}



const Page404 = () => {
    return (
        <>
            <div class="h2_footer-top pt-130 pb-135 bg-default"
                data-background="assets/images/footer/home2/bg.jpg">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-12">
                            <div class="h2_footer-top-content">
                                <h2 class="h2_footer-top-title tp_fade_bottom">404!</h2>
                                <big>
                                Requested Page Not Found...
                                </big>
                                <br /> <br />
                                <Link to={"/"}> <button className='btn btn-primary'> Back to home</button> </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="h2_footer-shape">
                    <img src="assets/images/footer/home2/shape-1.png" alt="" class="h2_footer-shape-1"
                        data-speed="0.8" />
                    <img src="assets/images/footer/home2/shape-2.png" alt="" class="h2_footer-shape-2"
                        data-speed="0.8" />
                    <img src="assets/images/footer/home2/shape-3.png" alt="" class="h2_footer-shape-3"
                        data-speed="0.8" />
                    <img src="assets/images/footer/home2/shape-4.png" alt="" class="h2_footer-shape-4"
                        data-speed="0.8" />
                </div>
            </div>
        </>
    )
}



export default P404