import React from 'react'
import { Link } from 'react-router-dom'
const IntroHeader = () => {
    return (
        <>
            <header class="h2_header-area header-sticky">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-2 col-lg-2 col-6">
                            <div class="h2_header-logo">
                                <Link to="/"><img src="/assets/images/logo/logo.png"
                                    alt="Image Not Found" /></Link>
                            </div>
                        </div>
                        <div class="col-xl-7 col-lg-7 text-center d-none d-lg-block">
                            <div class="h2_header-menu d-none d-lg-flex justify-content-center">
                                <nav class="h2_header-nav-menu" id="mobile-menu">
                                    <ul>
                                        <li> <Link to="/">Home</Link>  </li>
                                        <li><a href="/#about">About</a></li>
                                        <li><a href="/#pricing">Pricing</a></li>
                                        {/* <li><Link to="#testimonials">Testimonials</Link></li>  */}
                                        <li><a href="/#faq">FAQ</a></li>
                                        <li><a href="/docs">Developer Access</a></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6">
                            <div class="h2_header-action-wrap d-flex align-items-center justify-content-end">
                                <div class="h2_header-action d-none d-sm-flex">
                                    <Link to="/dashboard" class="h2_header-action-login">
                                        Goto Console
                                    </Link>
                                    <Link to="/signup" class="h2_header-action-btn theme-btn-2">
                                        Start Trial
                                    </Link>
                                </div>
                                <div class="header-menu-bar d-lg-none ml-10">
                                    <span class="header-menu-bar-icon side-toggle">
                                        <i class="fa-light fa-bars"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default IntroHeader