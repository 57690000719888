import { useState, useEffect, React } from 'react'
import IntroHeader from './IntroHeader'
import { Helmet } from 'react-helmet'
import IntroFooter from './IntroFooter'
import "./contact.css"
const Contact = () => {
    const [loaderStatus, setLoaderStatus] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoaderStatus(false)
        }, 1000);
    }, [])
    return (
        <>
            <Helmet>
                <title>Contact us | DSI LLC</title>
                <link rel="stylesheet" href="assets/css/all.min.css" />
                <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
                <link rel="stylesheet" href="assets/css/swiper-bundle.min.css" />
                <link rel="stylesheet" href="assets/css/meanmenu.css" />
                <link rel="stylesheet" href="assets/css/magnific-popup.css" />
                <link rel="stylesheet" href="assets/css/nice-select.css" />
                <link rel="stylesheet" href="assets/css/backtotop.css" />
                <link rel="stylesheet" href="assets/css/main.css" />
            </Helmet>
            {loaderStatus && <div className="loader" id='loader'></div>}
            <div class="has-smooth" id="has_smooth"></div>

            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <div class="body-wrapper">
                        <IntroHeader />
                        <div class="h2_footer-top pt-130 pb-135 bg-default"
                            data-background="assets/images/footer/home2/bg.jpg">
                            <div class="container">
                                <div class="row justify-content-between">
                                    <div class="col-12">
                                        <div class="h2_footer-top-content">
                                            <h2 class="h2_footer-top-title tp_fade_bottom">Connect with us</h2>
                                            <form action="#" class="tp_fade_bottomx">
                                                <div className="form">
                                                    <input type="text" name="name" id="name" placeholder='Enter your name' /> <br />
                                                    <input type="email" name="email" id="email" placeholder='Enter your email' /> <br />
                                                    <input type="text" name="subject" id="subject" placeholder='Enter subject' /> <br />
                                                    <textarea name="message" id="message" cols="30" rows="5" placeholder='Enter message'></textarea>
                                                    <center>
                                                    <input type="submit" value="SUBMIT" />
                                                    </center>
                                                    <br />
                                                    <p className="text-primary">By submitting, you are agree with privacy policy of DSI LLC</p>
                                                </div>
                                                                                                
                                            </form>
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="h2_footer-shape">
                                <img src="assets/images/footer/home2/shape-1.png" alt="" class="h2_footer-shape-1"
                                    data-speed="0.8" />
                                <img src="assets/images/footer/home2/shape-2.png" alt="" class="h2_footer-shape-2"
                                    data-speed="0.8" />
                                <img src="assets/images/footer/home2/shape-3.png" alt="" class="h2_footer-shape-3"
                                    data-speed="0.8" />
                                <img src="assets/images/footer/home2/shape-4.png" alt="" class="h2_footer-shape-4"
                                    data-speed="0.8" />
                            </div>
                        </div>
                        <IntroFooter />

                         
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact