import React from 'react'

const IntroFaq = () => {
    return (
        <>
            {/* <!-- faq area start --> */}
            <section class="h2_faq-area" id='faq'>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-7 col-lg-8 col-md-10">
                            <div class="h2_section-area text-center mb-50">
                                <span class="h2_section-subtitle tp_subtitle_anim">Have Any Questions</span>
                                <h2 class="h2_section-title tp_title_slideup mb-0">Frequently Asked Questions
                                    (FAQ) on Doodle</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-xxl-9 col-xl-10">
                            <div class="h2_faq-wrap tp_fade_bottom">
                                <div class="h2_faq-content">
                                    <div class="accordion" id="Expp">
                                        <div class="accordion-item mb-30">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                    aria-expanded="true" aria-controls="collapseOne">What is
                                                    Doodle Content Writing Tool?
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show"
                                                aria-labelledby="headingOne" data-bs-parent="#Expp">
                                                <div class="accordion-body">
                                                    <p>
                                                        Sed Mattis eros lectors, eu fermentum Felis laborites
                                                        convallis. Nam Felis arco, sed mi Faubus quips. Fusco id
                                                        dui nil. Sed ac lorem a dolor incident suscept quips
                                                        Purus. Poetesque auctor fugit Elementa. ante ipsum
                                                        primes in Faubus orca lotus et utricles
                                                        poseurs cub ilia curare.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item mb-30">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">How is the
                                                    data security of our chatbots ensured?
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse"
                                                aria-labelledby="headingTwo" data-bs-parent="#Expp">
                                                <div class="accordion-body">
                                                    <p>
                                                        Sed Mattis eros lectors, eu fermentum Felis laborites
                                                        convallis. Nam Felis arco, sed mi Faubus quips. Fusco id
                                                        dui nil. Sed ac lorem a dolor incident suscept quips
                                                        Purus. Poetesque auctor fugit Elementa. ante ipsum
                                                        primes in Faubus orca lotus et utricles
                                                        poseurs cub ilia curare.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item mb-30">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                    aria-expanded="false" aria-controls="collapseThree">How does
                                                    the learning process of AI chatbots work?
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse"
                                                aria-labelledby="headingThree" data-bs-parent="#Expp">
                                                <div class="accordion-body">
                                                    <p>
                                                        Sed Mattis eros lectors, eu fermentum Felis laborites
                                                        convallis. Nam Felis arco, sed mi Faubus quips. Fusco id
                                                        dui nil. Sed ac lorem a dolor incident suscept quips
                                                        Purus. Poetesque auctor fugit Elementa. ante ipsum
                                                        primes in Faubus orca lotus et utricles
                                                        poseurs cub ilia curare.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item mb-30">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                    aria-expanded="false" aria-controls="collapseFour">What are
                                                    the benefits of AI Chatbots to my business?
                                                </button>
                                            </h2>
                                            <div id="collapseFour" class="accordion-collapse collapse"
                                                aria-labelledby="headingFour" data-bs-parent="#Expp">
                                                <div class="accordion-body">
                                                    <p>
                                                        Sed Mattis eros lectors, eu fermentum Felis laborites
                                                        convallis. Nam Felis arco, sed mi Faubus quips. Fusco id
                                                        dui nil. Sed ac lorem a dolor incident suscept quips
                                                        Purus. Poetesque auctor fugit Elementa. ante ipsum
                                                        primes in Faubus orca lotus et utricles
                                                        poseurs cub ilia curare.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item mb-30">
                                            <h2 class="accordion-header" id="headingFive">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                                    aria-expanded="false" aria-controls="collapseFive">How are
                                                    AI writers impacting the writing industry?
                                                </button>
                                            </h2>
                                            <div id="collapseFive" class="accordion-collapse collapse"
                                                aria-labelledby="headingFive" data-bs-parent="#Expp">
                                                <div class="accordion-body">
                                                    <p>
                                                        Sed Mattis eros lectors, eu fermentum Felis laborites
                                                        convallis. Nam Felis arco, sed mi Faubus quips. Fusco id
                                                        dui nil. Sed ac lorem a dolor incident suscept quips
                                                        Purus. Poetesque auctor fugit Elementa. ante ipsum
                                                        primes in Faubus orca lotus et utricles
                                                        poseurs cub ilia curare.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item mb-30">
                                            <h2 class="accordion-header" id="headingSix">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseSix"
                                                    aria-expanded="false" aria-controls="collapseSix">How do our
                                                    hatbots improve customer support processes?
                                                </button>
                                            </h2>
                                            <div id="collapseSix" class="accordion-collapse collapse"
                                                aria-labelledby="headingSix" data-bs-parent="#Expp">
                                                <div class="accordion-body">
                                                    <p>
                                                        Sed Mattis eros lectors, eu fermentum Felis laborites
                                                        convallis. Nam Felis arco, sed mi Faubus quips. Fusco id
                                                        dui nil. Sed ac lorem a dolor incident suscept quips
                                                        Purus. Poetesque auctor fugit Elementa. ante ipsum
                                                        primes in Faubus orca lotus et utricles
                                                        poseurs cub ilia curare.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntroFaq