import React, { useEffect, useState } from 'react'
import Sidebar from '../widgets/Sidebar'
import Header from '../widgets/Header'
import { Helmet } from 'react-helmet'
import Cookies from 'universal-cookie'
import { Link } from 'react-router-dom'

const Dashboard = () => {
    const [name, setName ] = useState()

    useEffect(()=> {
        const cookie = new Cookies(); 
        setName(cookie.get("name"))
    },[])
    return (
        <>
            <Helmet>
                <title>Console - DSI Talk</title>
            </Helmet>
            <div className="wrapper">

                <Header />


                <Sidebar />
                <div className="content-page">
                    <div className="container-fluid h-100">
                        <div className="row align-self-center h-100">
                            <div className="col-sm-12 text-center align-self-center">
                                <h2 className="mb-4">Welcome Back , { name && name }</h2>
                                <img src="/assets/images/layouts/layout-01.png" className="img-fluid" alt="logo" style={{maxHeight:"30vh"}} />
                                <h4 className="mt-4 mb-3">Start Conversation with your contacts</h4>
                                <p className="mb-5">There are no analytic report currently...</p>
                                <div className="mb-4">
                                    <Link to="/chat" className="btn btn-primary  mr-3" data-target=".meeting-modal"
                                        data-toggle="modal">Start Chat Now</Link>
                                     
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>

        </>
    )
}

export default Dashboard