import React, { useEffect, useState } from 'react'
import IntroHeader from './IntroHeader'
import IntroFooter from './IntroFooter'
import IntroFaq from './IntroFaq'
import IntroPrice from './IntroPrice'
import IntroTestimonial from './IntroTestimonial'
import { Helmet } from 'react-helmet'

export const IntroScreen = () => {

  const [loaderStatus, setLoaderStatus] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false)
    }, 1000);
  }, [])
  return (
    <>
      <Helmet>
        <title>Introducing DSI Chat | DSI LLC </title>
        <link rel="stylesheet" href="assets/css/all.min.css" />
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/swiper-bundle.min.css" />
        <link rel="stylesheet" href="assets/css/meanmenu.css" />
        <link rel="stylesheet" href="assets/css/magnific-popup.css" />
        <link rel="stylesheet" href="assets/css/nice-select.css" />
        <link rel="stylesheet" href="assets/css/backtotop.css" />
        <link rel="stylesheet" href="assets/css/main.css" />
      </Helmet>
      {loaderStatus && <div className="loader" id='loader'></div>}
      <div class="rts-cursor cursor-outer" data-default="yes" data-link="yes" data-slider="no">
        <span class="fn-cursor"></span>
      </div>
      <div class="rts-cursor cursor-inner" data-default="yes" data-link="yes" data-slider="no">
        <span class="fn-cursor">
          <span class="fn-left"></span>
          <span class="fn-right"></span>
        </span>
      </div>
      {/* <!-- Cursor end --> */}

      {/* <!-- back to top start --> */}
      <div class="progress-wrap">
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      {/* <!-- back to top end --> */}

      {/* <!-- sidebar-information-area-start --> */}
      <div class="sidebar-info side-info">
        <div class="sidebar-logo-wrapper mb-25">
          <div class="row align-items-center">
            <div class="col-xl-6 col-8">
              <div class="sidebar-logo">
                <a href="index.html"><img src="assets/images/logo/logo-w.png" alt="logo-img" /></a>
              </div>
            </div>
            <div class="col-xl-6 col-4">
              <div class="sidebar-close-wrapper text-end">
                <button class="sidebar-close side-info-close"><i class="fal fa-times"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar-menu-wrapper fix">
          <div class="mobile-menu"></div>
        </div>
      </div>
      <div class="offcanvas-overlay"></div>
      {/* <!-- sidebar-information-area-end --> */}

      <div class="has-smooth" id="has_smooth"></div>

      <div id="smooth-wrapper">
        <div id="smooth-content">
          <div class="body-wrapper">
            <IntroHeader />

            <main>
              {/* <!-- banner area start --> */}
              <section class="h2_banner-area">
                <div class="h2_banner-single bg-default" data-background="assets/images/banner/home2/bg.jpg">
                  <div class="container">
                    <div class="h2_banner-content">
                      <span class="h2_banner-content-subtitle tp_subtitle_anim">👋 Hey there! We’re
                        DSI Chat Members</span>
                      <h1 class="h2_banner-content-title tp_has_text_reveal_anim">Introducing @DSI Chat</h1>
                      <p class="tp_fade_bottom">One of the best live chat application, supports Android, iOS, Web, Desktop <br /> with multiple programming languages.  Let's start a free trial...</p>
                    </div>
                    <div class="h2_banner-bottom">
                      <img src="assets/images/banner/home2/form_shape.png" alt=""
                        class="h2_banner-bottom-shape tp_fade_right" />
                      <div class="h2_banner-form tp_fade_bottom">
                        <h5 class="h2_banner-form-title">How can I help you?</h5>
                        <form action="#">
                          <input type="text" placeholder="Start with a detailed description" />
                          <button type="submit"><i class="fa-solid fa-paper-plane"></i>Send</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="h2_banner-shape">
                  <img src="assets/images/banner/home2/shape-1.png" alt="Image Not Found"
                    class="h2_banner-shape-1" data-speed="0.8" />
                  <img src="assets/images/banner/home2/shape-2.png" alt="Image Not Found"
                    class="h2_banner-shape-2" data-speed="0.7" />
                  <img src="assets/images/banner/home2/shape-3.png" alt="Image Not Found"
                    class="h2_banner-shape-3" data-speed="0.9" />
                  <img src="assets/images/banner/home2/shape-4.png" alt="Image Not Found"
                    class="h2_banner-shape-4" data-speed="0.8" />
                  <img src="assets/images/banner/home2/shape-5.png" alt="Image Not Found"
                    class="h2_banner-shape-5" />
                </div>
              </section>
              {/* <!-- banner area end --> */}

              {/* <!-- brand area start --> */}
              <section class="h2_brand-area pb-140 pt-135">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-xxl-8 col-xl-9 col-lg-9">
                      <div class="h2_section-area text-center mb-50">
                        <span class="h2_section-subtitle tp_subtitle_anim">Our Client</span>
                        <h2 class="h2_section-title tp_title_slideup mb-0">15,000+ Professionals & Teams
                          Choose Doodle</h2>
                      </div>
                    </div>
                  </div>
                  <div class="h2_brand-wrap">
                    <div class="h2_brand-item tp_fade_bottom">
                      <img src="assets/images/brand/1.png" alt="Image Not Found" />
                    </div>
                    <div class="h2_brand-item tp_fade_bottom">
                      <img src="assets/images/brand/2.png" alt="Image Not Found" />
                    </div>
                    <div class="h2_brand-item tp_fade_bottom">
                      <img src="assets/images/brand/3.png" alt="Image Not Found" />
                    </div>

                    <div class="h2_brand-item tp_fade_bottom">
                      <img src="assets/images/brand/4.png" alt="Image Not Found" />
                    </div>
                    <div class="h2_brand-item tp_fade_bottom">
                      <img src="assets/images/brand/5.png" alt="Image Not Found" />
                    </div>
                  </div>
                </div>
              </section>
              {/* <!-- brand area end --> */}

              {/* <!-- about area start --> */}
              <section class="h2_about-area pb-100" id='about'>
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-xl-6 col-lg-6">
                      <div class="h2_about-img mb-40">
                        <img src="assets/images/about/home2/bg.png" alt="Image Not Found"
                          class="inner-img-1 tp_fade_right" />
                        <img src="assets/images/about/home2/bg-2.png" alt="Image Not Found"
                          class="inner-img-2 tp_fade_bottom" />
                        <img src="assets/images/about/home2/shape.png" alt="Image Not Found"
                          class="inner-img-shape" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                      <div class="h2_about-content mb-40">
                        <div class="h2_section-area mb-40">
                          <span class="h2_section-subtitle tp_subtitle_anim">Who We Are?</span>
                          <h2 class="h2_section-title tp_title_slideup mb-25">What Makes The Doodle
                            Team Different?</h2>
                          <p class="h2_section-text tp_fade_bottom">Sed Mattis eros lectors, eu
                            fermentum Felis laborites convallis. Nam Felis arco, sed <br /> mi Faubus
                            quips. Fusco id dui nil. Sed ac lorem a dolor incident suscept <br />
                            Poetesqueo.</p>
                          <p class="h2_section-text mt-10 tp_fade_bottom">Sed Mattis eros lectors, eu
                            fermentum Felis laborites convallis. Nam Felis arco, sed <br /> mi Faubus
                            quips. Fusco id dui nil.</p>
                        </div>
                        <a href="#" class="theme-btn-2 tp_fade_bottom">Explore Now</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <!-- about area end --> */}

              {/* <!-- work area start --> */}
              <section class="h2_work-area bg-default pt-140 pb-140"
                data-background="assets/images/work/home2/bg.jpg">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-xl-5 col-lg-5 col-md-6">
                      <div class="h2_work-content mb-50 mb-md-0">
                        <div class="h2_section-area mb-20">
                          <span class="h2_section-subtitle tp_subtitle_anim">Working Process</span>
                          <h2 class="h2_section-title tp_title_slideup mb-30">
                            Constantly Improving Itself</h2>
                          <p class="h2_section-text tp_fade_bottom">There are many variations of
                            passages of Lorem Ipsum available, but the majority have suffered
                            alteration in some form, by injected humourThere are many variations of
                            passages.</p>
                        </div>
                        <ul class="h2_work-content-list tp_fade_bottom">
                          <li>Learning and Development Ability</li>
                          <li>Ease of Integration</li>
                        </ul>
                        <a href="#" class="theme-btn-2 tp_fade_bottom">Explore More</a>
                      </div>
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-6">
                      <div class="h2_work-img tp_fade_left">
                        <img src="assets/images/work/home2/bg.png" alt="Image Not Found" />
                      </div>
                    </div>
                  </div>
                  <div class="h2_work-bottom pt-80">
                    <div class="h2_work-item h2_work-item-1 tp_has_fade_anim" data-fade-from="bottom">
                      <div class="h2_work-item-icon">
                        <i class="fa-solid fa-star"></i>
                      </div>
                      <div class="h2_work-item-info">
                        <span>Data Processing</span>
                      </div>
                    </div>
                    <div class="h2_work-item h2_work-item-2 tp_has_fade_anim" data-fade-from="bottom"
                      data-delay=".8">
                      <div class="h2_work-item-icon">
                        <i class="fa-solid fa-comments"></i>
                      </div>
                      <div class="h2_work-item-info">
                        <span>Generating a Response</span>
                      </div>
                    </div>
                    <div class="h2_work-item h2_work-item-3 tp_has_fade_anim" data-fade-from="bottom"
                      data-delay="1.1">
                      <div class="h2_work-item-icon">
                        <i class="fa-solid fa-rocket-launch"></i>
                      </div>
                      <div class="h2_work-item-info">
                        <span>Integration and implementation</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="h2_work-shape d-none d-md-block">
                  <img src="assets/images/work/home2/shape-1.png" alt="Image Not Found"
                    class="h2_work-shape-1" data-speed="0.6" />
                  <img src="assets/images/work/home2/shape-2.png" alt="Image Not Found"
                    class="h2_work-shape-2" data-speed="0.8" />
                  <img src="assets/images/work/home2/shape-3.png" alt="Image Not Found"
                    class="h2_work-shape-3" data-speed="0.8" />
                  <img src="assets/images/work/home2/shape-4.png" alt="Image Not Found"
                    class="h2_work-shape-4" data-speed="0.7" />
                </div>
              </section>

              {/* <IntroTestimonial /> */}

              <IntroPrice />

              <IntroFaq />
            </main>

            <IntroFooter />

          </div>
        </div>
      </div>

      <Helmet>
        <script src="assets/js/jquery-3.6.0.min.js"></script>
        <script src="assets/js/bootstrap.bundle.min.js"></script>
        <script src="assets/js/swiper-bundle.min.js"></script>
        <script src="assets/js/meanmenu.min.js"></script>
        <script src="assets/js/gsap.min.js"></script>
        <script src="assets/js/ScrollSmoother.min.js"></script>
        <script src="assets/js/ScrollTrigger.min.js"></script>
        <script src="assets/js/TweenMax.min.js"></script>
        <script src="assets/js/SplitText.min.js"></script>
        <script src="assets/js/chroma.min.js"></script>
        <script src="assets/js/magnific-popup.min.js"></script>
        <script src="assets/js/nice-select.min.js"></script>
        <script src="assets/js/backtotop.js"></script>
        <script src="assets/js/main.js"></script>


      </Helmet>
    </>
  )
}
