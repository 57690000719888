import React from 'react'

const IntroPrice = () => {
    return (
        <>
            <section class="price-area h2_price-area price-tab pb-135" id='pricing'>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-7 col-lg-7 col-md-10">
                            <div class="h2_section-area text-center mb-50">
                                <span class="h2_section-subtitle tp_subtitle_anim">Pricing Plan</span>
                                <h2 class="h2_section-title tp_title_slideup mb-0">Ready to Get Started? We'll
                                    Keep You Under Budget</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="price-switch-wrapper tp_fade_bottom">
                                <label class="toggler toggler--is-active" id="filt-monthly">Pay Monthly</label>
                                <div class="toggle">
                                    <input type="checkbox" id="switcher" class="tp-check" />
                                    <b class="switch"></b>
                                </div>
                                <label class="toggler yearly-pack" id="filt-yearly">Pay Yearly<span
                                    class="amount-offer">Save 34%</span></label>
                            </div>
                        </div>
                    </div>
                    <div id="monthly" class="wrapper-full">
                        <div class="row align-items-end">
                            <div class="col-xl-4 col-md-6 tp_fade_left" data-fade-from="left">
                                <div class="price-item price-item-1">
                                    <div class="price-item-head">
                                        <h5 class="price-item-title">Free Trial</h5>
                                        <h2 class="price-item-amount-title">Free</h2>
                                        <p>No Need For Credit Card</p>
                                        <a class="price-item-btn" href="#">Choose Plan<i
                                            class="fa-light fa-angle-right"></i></a>
                                    </div>
                                    <ul class="price-item-list">
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Priority email & chat support 0
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Access 12+ use-cases
                                        </li>
                                        <li class="not-abatable">
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Generate 1,000 AI Words / month
                                        </li>
                                        <li class="not-abatable">
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Google Docs style editor
                                        </li>
                                        <li class="not-abatable">
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Compose & command features
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-6 tp_fade_left" data-fade-from="left" data-delay=".8">
                                <div class="price-item active">
                                    <div class="price-item-option">
                                        <span>Best Choice</span>
                                    </div>
                                    <div class="price-item-head">
                                        <h5 class="price-item-title">Standard Plan</h5>
                                        <div class="price-item-amount"><del>$85</del>
                                            <h2 class="price-item-amount-title">$35<span>.8</span></h2><span
                                                class="amount-offer">49.2%</span>
                                        </div>
                                        <p>/Month (annually billed)</p>
                                        <span class="price-item-offer">SAVE UP TO $54.2</span>
                                        <a class="price-item-btn" href="#">Choose Plan<i
                                            class="fa-light fa-angle-right"></i></a>
                                    </div>
                                    <ul class="price-item-list">
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Priority email & chat support 0
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Access 12+ use-cases
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Generate 1,000 AI Words / month
                                        </li>
                                        <li class="not-abatable">
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Google Docs style editor
                                        </li>
                                        <li class="not-abatable">
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Compose & command features
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-6 tp_fade_left" data-fade-from="left" data-delay="1.1">
                                <div class="price-item">
                                    <div class="price-item-head">
                                        <h5 class="price-item-title">Premium Plan</h5>
                                        <div class="price-item-amount"><del>$99</del>
                                            <h2 class="price-item-amount-title">$89<span>.8</span></h2><span
                                                class="amount-offer">30%</span>
                                        </div>
                                        <p>/Month (annually billed)</p>
                                        <span class="price-item-offer">SAVE UP TO $27.2</span>
                                        <a class="price-item-btn" href="#">Choose Plan<i
                                            class="fa-light fa-angle-right"></i></a>
                                    </div>
                                    <ul class="price-item-list">
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Priority email & chat support 0
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Access 12+ use-cases
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Generate 1,000 AI Words / month
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Google Docs style editor
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Compose & command features
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="hourly" class="wrapper-full hide">
                        <div class="row align-items-end">
                            <div class="col-xl-4 col-md-6 tp_fade_left" data-fade-from="left">
                                <div class="price-item price-item-1">
                                    <div class="price-item-head">
                                        <h5 class="price-item-title">Free Trial</h5>
                                        <h2 class="price-item-amount-title">Free</h2>
                                        <p>No Need For Credit Card</p>
                                        <a class="price-item-btn" href="#">Choose Plan<i
                                            class="fa-light fa-angle-right"></i></a>
                                    </div>
                                    <ul class="price-item-list">
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Priority email & chat support 0
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Access 12+ use-cases
                                        </li>
                                        <li class="not-abatable">
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Generate 1,000 AI Words / month
                                        </li>
                                        <li class="not-abatable">
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Google Docs style editor
                                        </li>
                                        <li class="not-abatable">
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Compose & command features
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-6 tp_fade_left" data-fade-from="left" data-delay=".8">
                                <div class="price-item active">
                                    <div class="price-item-option">
                                        <span>Best Choice</span>
                                    </div>
                                    <div class="price-item-head">
                                        <h5 class="price-item-title">Standard Plan</h5>
                                        <div class="price-item-amount"><del>$85</del>
                                            <h2 class="price-item-amount-title">$55<span>.8</span></h2><span
                                                class="amount-offer">49.2%</span>
                                        </div>
                                        <p>/Month (annually billed)</p>
                                        <span class="price-item-offer">SAVE UP TO $54.2</span>
                                        <a class="price-item-btn" href="#">Choose Plan<i
                                            class="fa-light fa-angle-right"></i></a>
                                    </div>
                                    <ul class="price-item-list">
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Priority email & chat support 0
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Access 12+ use-cases
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Generate 1,000 AI Words / month
                                        </li>
                                        <li class="not-abatable">
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Google Docs style editor
                                        </li>
                                        <li class="not-abatable">
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Compose & command features
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-6 tp_fade_left" data-fade-from="left" data-delay="1.1">
                                <div class="price-item">
                                    <div class="price-item-head">
                                        <h5 class="price-item-title">Premium Plan</h5>
                                        <div class="price-item-amount"><del>$99</del>
                                            <h2 class="price-item-amount-title">$99<span>.8</span></h2><span
                                                class="amount-offer">30%</span>
                                        </div>
                                        <p>/Month (annually billed)</p>
                                        <span class="price-item-offer">SAVE UP TO $27.2</span>
                                        <a class="price-item-btn" href="#">Choose Plan<i
                                            class="fa-light fa-angle-right"></i></a>
                                    </div>
                                    <ul class="price-item-list">
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Priority email & chat support 0
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Access 12+ use-cases
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Generate 1,000 AI Words / month
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Google Docs style editor
                                        </li>
                                        <li>
                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.74927 6.38277C5.74927 6.38277 4.0184 5.19279 1.96289 4.76003L5.9657 9.30371L13.1058 -3.8147e-06C13.1058 -3.8147e-06 8.12943 2.70451 5.74927 6.38277Z"
                                                    fill="currentColor" />
                                                <path
                                                    d="M10.085 6.25711C9.82712 6.25711 9.61806 6.46617 9.61806 6.72406C9.61806 7.88424 9.16634 8.97444 8.34583 9.79428C7.52632 10.6145 6.43621 11.0662 5.27594 11.0662C2.88165 11.0662 0.933817 9.11835 0.933817 6.72406C0.933817 5.56379 1.38564 4.4736 2.20614 3.65384C3.02548 2.83366 4.11576 2.38193 5.27594 2.38193C5.51669 2.38193 5.75803 2.40175 5.99318 2.4408C6.24739 2.48312 6.48806 2.3111 6.53029 2.05664C6.57252 1.80226 6.40051 1.56184 6.14621 1.51961C5.86047 1.47212 5.5677 1.44812 5.27594 1.44812C3.86606 1.44812 2.54122 1.9971 1.54585 2.99355C0.549067 3.98934 0 5.31426 0 6.72406C0 9.63322 2.36678 12 5.27594 12C6.68582 12 8.01058 11.451 9.00603 10.4545C10.003 9.45861 10.5519 8.13394 10.5519 6.72406C10.5519 6.46617 10.3429 6.25711 10.085 6.25711Z"
                                                    fill="currentColor" />
                                            </svg> Compose & command features
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- price area end --> */}
        </>
    )
}

export default IntroPrice